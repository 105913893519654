'use strict'
import axios from 'axios'

const Form =  
{
    init: function()
    {
        const form = document.getElementById('form')
        
        if(form)
        {
            let company = form.querySelector('#company')
            let lastname = form.querySelector('#lastname')
            let firstname = form.querySelector('#firstname')
            let email = form.querySelector('#email')
            let optin = form.querySelector('#optin')
            let solution = form.querySelector('#solution')
            let fields = [company, lastname, firstname, email, optin, solution]
            let errormsgs = form.querySelectorAll('[id^=error-]')

            form.addEventListener('submit', async (e) => 
            {

                e.preventDefault()

                // Reset errors
                let errors = []
                errormsgs.forEach(msg => {
                    msg.textContent = ''
                })
                form.classList.remove('--haserrors')

                // Check lastname
                if(lastname.value === '')
                {
                    let error = 
                    {
                        name: 'lastname',
                        message: 'Merci de renseigner votre nom.'
                    }

                    errors.push(error)
                }

                // Check firstname
                if(firstname.value === '')
                {
                    let error = 
                    {
                        name: 'firstname',
                        message: 'Merci de renseigner votre prénom.'
                    }

                    errors.push(error)
                }

                // Check email 
                if(email.value === '')
                {
                    let error = 
                    {
                        name: 'email',
                        message: 'Merci de renseigner votre adresse e-mail.'
                    }
                    errors.push(error)
                }

                if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.value))
                {
                    let error = 
                    {
                        name: 'email',
                        message: 'L\'adresse e-mail est incorrecte.'
                    }
                    errors.push(error)
                }

                // Check optin
                if(!optin.checked){
                    let error = 
                    {
                        name: 'optin',
                        message: 'Merci d\'accepter la politique d\'utilisation des données personnelles.'
                    }
                    errors.push(error)
                }

                // Check captcha
                if(solution.value === '')
                {
                    let error = 
                    {
                        name: 'captcha',
                        message: 'Merci de valider le captcha.'
                    }
                    errors.push(error)
                }

                // Call API
                if(errors.length === 0)
                {
                    let data = new FormData()
                    fields.forEach(field => {
                        let value = field.type === 'checkbox' ? 'on' : field.value
                        data.append(field.name, value)
                    })

                    try 
                    {
                        const response = await axios({
                            url: '/app/index.php', 
                            method: 'post',
                            data
                        })

                        if(response.data.success){
                            form.classList.add('--success')
                        }
                        else
                        {
                            document.querySelector('#error-global').textContent = "Désolé, une erreur est survenue."
                            form.classList.add('--haserrors')
                        }
                    }
                    catch (error)
                    {
                        console.log(error)
                    }
                }
                // Handle Errors
                else
                {   
                    errors.forEach( error => {
                        let element = document.getElementById(`error-${error.name}`)
                        element.textContent = error.message
                    })

                    form.classList.add('--haserrors')
                }
            })
        }
    }
}

export default Form;